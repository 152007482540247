/*jshint esversion: 6 */
const $ = jQuery;

'use strict';
$(function () {

    const login = $("#login");
    //login.find('.forgetmenot').remove();
    login.find('#backtoblog').remove();
    login.find('#nav').remove();
    login.find('.privacy-policy-page-link').remove();
});